.admin-categories-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 100px;
}

.admin-categories-form-icon {
  background: rgb(255,236,110);
  background: linear-gradient(45deg, rgba(255,236,110,1) 0%, rgba(252,176,69,1) 50%);
  color: white;
  border-radius: 50%;
  border: 3px solid #ffffff;
  padding: 18px;
  box-sizing: border-box;
  outline: 5px solid #52a9f5;
  overflow: inherit !important;
}