.on-boarding {
  &-container {
    width: 100%;
    box-sizing: border-box;
    padding: 90px 30px 0 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__publish {
    position: fixed;
    bottom: 0;
    width: calc(100% - 60px);
    padding-top: 60px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
    transition: all 200ms;
    background: linear-gradient(
      #ffffff00,
      rgba(255, 255, 255, 0.61),
      rgb(255, 255, 255)
    );
    &-buttons {
      width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      //   margin-left: 60px;
      //   box-shadow: 0px 4px 30px 0px #00000014;
      padding: 30px;
    }
  }
  &-preview {
    width: 400px;
    &__input {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-self: flex-start;
      margin-top: 10px;
      & label {
        color: #0f0f0f;
        padding: 0;
        margin: 0;
        transform: translateY(5px);
      }
      & h3 {
        color: #0f0f0f;
        padding: 0;
        margin: 0;
      }
      &-img {
        object-fit: cover;
        height: 100px;
        width: 100px;
        border-radius: 10px;
      }
    }
    &__item {
      width: 100%;
      border-radius: 22px;
      padding: 30px;
      background-color: #fff;
      margin-top: 15px;
      box-shadow: 0px 4px 30px 0px #00000014;
      &__header {
        & h3 {
          margin: 0 !important;
          padding: 0 !important;
        }
        & button {
          border-style: none;
          background-color: #fff;
          //   box-shadow: 0px 4px 30px 0px #00000014;
          padding: 10px;
          height: 45px;
          width: 45px;
          border-radius: 50px;
          transition: all 300ms;
          cursor: pointer;

          &:hover {
            // background-color: #f5f5f5;
            box-shadow: 0px 4px 30px 0px #00000014;
          }
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  &__card {
    position: sticky;
    top: 90px;
    margin-bottom: 100;
    &-container {
      //   width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: sticky;
      top: 60;
    }
    &-holder {
      display: flex;
      justify-content: center;
      align-self: flex-start;
      position: sticky;
      top: 60;
    }
    width: 400px;
    padding: 30px;
    max-width: max-content;
    background-color: #fff;
    border-radius: 22px;
    box-shadow: 0px 4px 30px 0px #00000014;
  }
  &__rewards {
    &-empty {
      width: 100%;
      //   height: 90px;
      border-radius: 22px;
      border: 1px dashed rgb(221, 221, 221);
      padding: 30px;
      box-sizing: border-box;
      & p {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
    &-container {
      width: 370px;
    }
  }
  &__buttons {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    box-sizing: border-box;
    margin-left: -30px;
    gap: 15px;
    transition: all 300ms;
    &-add {
      background-color: #fff !important;
      border: 1px solid rgb(221, 221, 221);
      & span {
        transition: all 300ms;
        color: rgb(22, 22, 22) !important;
      }
      &:hover {
        border: 1px solid #52a9f5;
        background-color: #52a9f5 !important;
        & span {
          color: #fff !important;
        }
      }
      &:disabled {
        background-color: rgb(221, 221, 221) !important;
        border: rgb(221, 221, 221) !important;
        & span {
          color: rgb(22, 22, 22) !important;
        }
      }
    }
  }
  &-children {
    // width: 400px;
    // transition: all 300ms;
  }
}
