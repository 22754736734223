.user-statistics-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    padding: 0 50px;
    height: max-content;
    box-sizing: border-box;
    margin-bottom: 30px;
    min-width: 900px;
}

.user-statistics-container__title {
    margin-bottom: 30px;
}

.user-statistics-container__subtitle {
    align-self: center;
    margin-bottom: 30px;
}

.user-statistics-container__row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
    opacity: 0.5;
}

.user-statistics-container__card {
    width: 30%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 6px 28px 0px rgba(0, 0, 0, 0.25);
    padding-right: 10px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.big-card {
    width: 65%;
}

.user-statistics-container__card-title {
    padding-left: 20px;
    padding-top: 20px;
    margin-bottom: 10px;
    align-self: center;
    font-weight: bold;
}

.user-statistics-container__card-body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}














// .statistics-container {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: flex-end;
//     padding: 0 50px;
//     height: max-content;
//     box-sizing: border-box;
//     margin-bottom: 30px;
//     &-r {
//       padding: 30px;
//       padding-bottom: 40px;
//       width: 100%;
//       border-radius: 10px;
//       background-color: #fff;
//       box-shadow: 0px 6px 28px 0px rgba(0, 0, 0, 0.25);
//       height: inherit;
//       &-t {
//         width: 100%;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//       }
//       &-b {
//         display: flex;
//         justify-content: space-between;
//         align-items: flex-end;
//         margin-top: 75px;
//       }
//     }
  
//     &-l {
//       min-width: 330px !important;
//       display: flex;
//       flex-direction: column;
//       justify-content: flex-start;
//       align-items: flex-start;
//       margin-right: 50px;
//       gap: 50px;
//       &-t {
//       }
  
//       &-card {
//         padding: 30px;
//         width: 100%;
//         border-radius: 10px;
//         background-color: #fff;
//         box-shadow: 0px 6px 28px 0px rgba(0, 0, 0, 0.25);
  
//         display: flex;
//         flex-direction: column;
//         justify-content: flex-start;
//         align-items: flex-start;
//         margin: 50px
  
//         &__title {
//           color: #0f0f0f;
//           font-size: 16px;
//           font-weight: 600;
//         }
//         &__core {
//           width: 100%;
//           display: flex;
//           flex-direction: column;
//           justify-content: center;
//           align-items: center;
//           margin-top: 30px;
//           margin-bottom: 60px;
  
//           & h2 {
//             margin: 0;
//             color: #52a9f5;
//             font-size: 60px;
//           }
//           & h3 {
//             margin: 0;
//             font-size: 28px;
//             text-align: center;
//             color: #0f0f0f;
//           }
//         }
//       }
//     }
//   }
  
//   .last-items {
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     box-sizing: border-box;
//     &-l {
//       color: #0f0f0f;
//       font-size: 16px;
//       font-weight: 600;
//       max-width: 90%;
//       text-align: left;
//       text-overflow: ellipsis;
//     }
//     &-r {
//       color: #52a9f5;
//       font-size: 16px;
//       font-weight: 600;
//     }
//   }
  
//   .bar-container {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: center;
//   }
  
//   .bar-children {
//     width: 50px;
//     border-radius: 15px 15px 0 0;
//     background: linear-gradient(#336aaf, #00a3f6);
//     margin-bottom: 15px;
//   }
  