.auth-layout-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(145deg, #004685, #00a3f6, #00a2f2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.layout-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &__b {
    width: 100%;
    margin-top: 60px;
    display: grid;
    grid-template-columns: 100px auto;

    &-r {
      width: 100%;
      box-sizing: border-box;
    }
  }
}
