.reward-card-container {
  width: 100%;
  min-width: 325px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 20px 0;
  border-bottom: 1px solid #ececec;
  transition: all 300ms;
  cursor: pointer;
  border-radius: 5px;
  &:hover .reward-card-container-fab {
    display: flex;
  }
  &-fab {
    transition: all 300ms;
    display: flex;

    position: absolute;
    transform: translateX(300px);
    height: 100px;
    justify-content: flex-end;
    align-items: center;
    display: none;
    &__holder {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      & button {
        border-style: none;
        background-color: #fff;
        border: 1px solid #f1f1f1;
        height: 35px;
        outline: none;
        width: 35px;
        border-radius: 20px;
        box-shadow: 0px 4px 30px 0px #00000014;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 300ms;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 4px 40px 0px #00000014;
          background-color: #52a9f5;
          & svg {
            color: #fff;
          }
        }
        & svg {
          transition: all 300ms;
          transform: scale(1.2);
        }
      }
      &--delete:hover {
        background-color: #e71c3e !important;
      }
    }
  }
  &-l {
    width: 120px;
    height: 90px;
    border-radius: 5px;
    background-color: #f1f1f1;
    object-fit: cover;
  }

  &-r {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    &-t {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }
    &-b {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &__coins {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        & img {
          height: 14px;
          width: 14px;
          border-radius: 8px;
          background-color: #f1f1f1;
        }
        & span {
          color: #52a9f5;
          font-size: 14px;
          margin-left: 2px;
          font-weight: 600;
          text-align: left;
        }
      }
      &__status {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & div {
          height: 6px;
          width: 6px;
          border-radius: 3px;
          background-color: #62c662;
        }

        & span {
          color: #2f2f2f;
          font-size: 14px;
          font-weight: 500;
          margin-left: 5px;
        }
      }
    }
  }
}

.reward-container {
  width: 370px;
  height: calc(100vh - 120px);
  min-height: 100px;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 0 25px 25px 0;
  box-sizing: border-box;
  padding: 30px 23px;
  box-shadow: 10px 10px 28px 0px rgba(0, 0, 0, 0.1);
  & h2 {
    color: #000;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__cards {
    margin-top: 5px;
    max-height: calc(100vh - 200px);
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
  }
}

.filtroo-modal {
  & .ant-modal-content {
    border-radius: 15px !important;
  }
  & .ant-modal-header {
    border-radius: 15px !important;
    border: none !important;
  }
  & .ant-modal-body {
    padding-top: 0 !important;
    & span {
      color: #a7a7a7;
    }
  }
}
