h3 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.25;
}

.Link {
  color: #007bff;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  word-wrap: break-word;
  background-color: aliceblue;
  width: 100%;
  word-break: break-all;
  padding: 0.5% 0;
  border-radius: 1.8rem;
  text-align: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.5rem 1rem;
}

.Detail_Info_Text {
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  background-color: aliceblue;
  border-radius: 1.5rem;
  padding: 0.5% 0;
}

.General_Container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(216, 219, 219);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 1.5rem;
  gap: 2rem;
  width: 100%;
  height: 100%;
  max-width: 100rem;
  padding: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;

}

.Image_Container {
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 600px;
  max-width: 300px;
}

.Image_Filter {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 1.5rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.Info_Container {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  max-height: fit-content;
  padding: 1rem;
}

.Detail_Info {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2.5rem;
}

.Buttons {
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
}
