.AdminUploadFilterScreen{
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__Content{
    background-color: #FFFFFF;
    box-shadow: 10px 10px 28px 0 rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    max-width: 600px;
    width: 90%;
    margin: 0 auto;

    &-title{
      text-align: center;
      margin: 0 auto;
      padding: 10px 0;
    }

    &-form{
      margin: 20px 20px;

      &-input{
        margin: 10px 0;
      }

      &-button{
        margin: 20px auto;
      }
    }

    &-result{
      margin: 20px 0;
      display: flex;
      flex-direction: column;

      &--error{
        text-align: center;
        color: red;
      }

      &--success{
        text-align: center;
        color: green;
      }
    }
  }
}