.input-container {
  width: 100%;
  border-style: none;
  background-color: transparent;
  padding-left: 15px;
  box-sizing: border-box;
  padding-bottom: 15px;
  color: #fff;
  border-bottom: 1px solid #90d5f6;
  outline: none;
  font-size: 14px;
  &::placeholder {
    color: rgb(233, 233, 233);
  }
}

.input-area {
  resize: none;
  padding: 15px;
  height: 110px !important;
}

.input-container--full {
  width: 100%;
  border-style: none;
  background-color: #f9f8f9;
  padding-left: 25px;
  box-sizing: border-box;
  height: 44px;
  border-radius: 22px;
  color: #000;
  outline: none;
  font-size: 14px;
  &::placeholder {
    color: rgb(22, 22, 22);
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.input-container--full-with-info {
  width: 92%;
  border-style: none;
  background-color: #f9f8f9;
  padding-left: 25px;
  box-sizing: border-box;
  height: 44px;
  border-radius: 22px;
  color: #000;
  outline: none;
  font-size: 14px;
  &::placeholder {
    color: rgb(22, 22, 22);
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.icon-info:hover {
  cursor: pointer
}

.input-container-info-text{
  display:flex;
  justify-content: center;
  color: hsla(0, 0%, 22%, 0.718)
}

.input-with-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-container {
  width: 20px;
  height: 20px;
  border-style: none;
  border-radius: 3px;
  border: 1px solid #fff;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms;
  &--checked {
    background-color: #fff;
  }
}

.dragger-container {
  border-radius: 22px;
  background-color: #f9f8f9;
  width: 125px;
  height: 85px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}
