.admin-home-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-pointsRewards-container {
  width: 100%;
  // height: calc(100vh - 120px);
  min-height: 100px;
  margin: 0 30px;
  margin-bottom: 20px;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 20%) 0px 1px 3px, rgb(0 0 0 / 10%) 0px 10px 15px -5px, rgb(0 0 0 / 40%) 0px 7px 7px -5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  // justify-content: center;

  &-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    align-self: flex-end;

    button {
      width: 140px;
      white-space: nowrap;
    }
  }
}

.admin-pointsRewards-form {
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: flex-end;
  justify-content: center;
  background-color: #e7e5e7;
  padding: 16px;
  border-radius: 10px;
  flex-wrap: wrap;

  &-input {
    width: 120px;
  }

  &-button {
    margin-bottom: 10px;
    min-width: 100px;
    max-width: 150px;
  }
}



