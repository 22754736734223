.go-back-container {
  height: 45px;
  width: 100%;
  border-style: none;
  max-width: 70px;
  border-radius: 25px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms;
  cursor: pointer;
  box-shadow: 0px 4px 30px 0px #00000014;
  transition: all 300ms;
  &:hover {
    background-color: #f5f5f5;
  }
}
