.profile {
  &__links {
    display: grid;
    margin-top: 15px;
    justify-items: center;
    align-items: center;
    gap: 10px;
    grid-template-columns: 325px;
  }
  &__banner {
    width: calc(100% - 60px);
    max-width: 1200px;
    margin-left: 30px;
    border-radius: 22px;
    margin-right: 30px;
    // margin-top: -29px;
    // border: 2px solid #f1f1f1;
    margin-bottom: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    &-dragger {
      transform: translateY(70px);
      border-radius: 24px;
      border: 2px solid #ffffff;
      box-shadow: 10px 10px 28px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
