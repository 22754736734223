.AdminUploadCampaignScreen{
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__Content{
    background-color: #FFFFFF;
    box-shadow: 10px 10px 28px 0 rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    max-width: 600px;
    width: 90%;
    margin: 0 auto;

    &-title{
      text-align: center;
      margin: 0 auto;
      padding: 10px 0;
    }


    &-sections {
      display: flex;
    }

    &-form{
      margin: 20px 20px;

      &-input{
        margin: 10px 0;
      }

      &-button{
        margin: 20px auto;
      }
    }

    &-result{
      margin: 20px 0;
      display: flex;
      flex-direction: column;

      &--error{
        text-align: center;
        color: red;
      }

      &--success{
        text-align: center;
        color: green;
      }
    }

    &-challenge {

      &-titles {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #a0a0a0;
        border-radius: 10px;
        padding: 8px;
      }

      &-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
      }
    }

    &-campaigns {
      &-draggers {
        margin-top: 20px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 8px;

        &-item{
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  .section-button {
    border: 1px solid #000000;

    &-active {
      border-color: #52a9f5;
      color: #52a9f5;
    }
  }
}