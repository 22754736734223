.First_Container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 89vh;
}

.swiper-container {
  width: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100%;
  margin-right: 10px;
  max-width: 240px;
  max-height: 650px;
}

.container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 700px;
  max-width: 1350px;
}

.card {
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
  width: 100%;
  margin: 0 auto;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem 0.5rem 0 0;
  transition: transform 0.3s;
}

.card:hover .card__image {
  transform: scale(1.01);
}

.card__content {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  background: rgba(237, 235, 235, 0.975);
  border-radius: 1rem;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  :hover {
    background: #00aaff;
  }
}

.card__title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  color: #000;
  margin-top: 0.5rem;
  text-align: center;
}

.Modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 1.5rem;
  font-weight: "bold";
  color: #000;
  text-align: center;
}
