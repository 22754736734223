.navbar-container {
  width: 100%;
  height: 60px;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;

  &__logo {
    height: 25px;
  }
  &__breadcrumb {
    color: #000000;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
  }
  &__r {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &-button {
      width: 30px;
      height: 30px;
      padding: 0;
      border-style: none;
      background-color: transparent;
      cursor: pointer;
    }
    &__dropdown {
      width: 150px;
      border-radius: 5px;
      padding: 15px 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      box-shadow: 0px 4px 30px 0px #00000014;
      background-color: #fff;

      & button {
        width: 100%;
        height: 40px;
        border-style: none;
        color: #000000;
        background-color: transparent;
        text-align: left;
        cursor: pointer;
        transition: all 300ms;
        padding-left: 15px;
        &:hover {
          background-color: #f5f5f5;
          // color: #fff;
        }
      }
    }
    &-modal {
      width: 200px;
      border-radius: 15px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0px 4px 30px 0px #00000014;
      padding: 20px;
      position: fixed;
      top: 50px;
      right: 15px;
      box-sizing: border-box;
      z-index: 9999999;
    }
    &-user {
      margin-left: 15px;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      background-color: #f1f1f1;
    }
  }
}
